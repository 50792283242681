import React from "react";
import {
  SubLayout,
  About,
  ClinicsParticle,
  CareerParticle,
  ContactParticle,
} from "../components";
import Seo from "../components/Seo";
// import Seo from "../components/seo";

const AboutPage = () => {
  return (
    <SubLayout>
      <Seo
        title="慶育会とは - 法人概要"
        description="医療法人社団慶育会のビジョンや法人概要を見ることができます。"
        keywords="医療法人社団 慶育会,グレースメディカルグループ,在宅医療,訪問診療,訪問医療,恵比寿美容皮膚科,法人概要"
      />
      <About />
      <ClinicsParticle />
      <CareerParticle />
      <ContactParticle />
    </SubLayout>
  );
};

export default AboutPage;
